import api from './api';

class UserService {
    getPublicContent() {
        return api.get('/test/all');
    }

    getUserBoard() {
        return api.get('/test/user');
    }

    getModeratorBoard() {
        return api.get('/test/mod');
    }

    getAdminBoard() {
        return api.get('/test/admin');
    }

    async LogIn(model) {
        return await api.post('Customer/CustomerLogin', model);
    }

    async getHeaderData(id, userIdentity) {
        userIdentity = localStorage.getItem('companyId');
        let actionType = "00Test";
        if (localStorage.getItem("actionType") == 'AsAObserver') {
            actionType = localStorage.getItem("actionType");
        }
        return await api.get(`Common/getHeaderData/${id}/${userIdentity}/${actionType}`);

    }
}

export default new UserService();   