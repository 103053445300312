import api from './api';

class userAuthService {
    async GetModuleList(userId, opType) {
        return await api.get(`Module/GetModuleList/${userId}/${opType}`);
    }

    getUserModulePermissions(userId, actionType) {
        if (actionType == '' || actionType == null || typeof actionType == undefined) {
            actionType = '00Test';
        }
        if (localStorage.getItem("actionType") == 'AsAObserver') {
            actionType = localStorage.getItem("actionType");
        }
        return api.get(`common/GetUserModulePermissionList/${userId}`);
    }
}

export default new userAuthService();