<template>
    <section class="page-body">
        <router-view />
        <Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" />
    </section>
    <DialogsWrapper />
</template>

<script>
    import layout from '@/Layout/layout.vue';
    import AuthUserServices from '@/service/AuthUserServices.js';

     export default {
        components: {
            layout
        },
         created()
         {
             localStorage.setItem("SupuserId","00000000-0000-0000-0000-000000000000");
             localStorage.setItem("SupcompanyId", "00000000-0000-0000-0000-000000000000");
             this.GetDynamicContent();
        },
        methods: {
            GetDynamicContent() {
                AuthUserServices.GetDynamicContentForComapny(localStorage.getItem("companyId")).then(res => {
                    const favicon = document.getElementById("favicon");
                    favicon.href = res.data.favIconLink;
                })
            },
        }

    }    
</script>

