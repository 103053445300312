import moment from 'moment';


function IsNull(value, CheckEmptyString = true, replaceValue = null) {
    if (value != null && value != undefined) {
        if (CheckEmptyString == true) {
            return value != '' ? value : null;
        }
        return value;
    }
    else {
        return replaceValue;
    }
}


var rightNow, jan1, june1, temp, jan2, june2;
function calculate_time_zone() {
    var minutes;

    localStorage["TimeZone"] != null;
    rightNow = new Date();
    jan1 = new Date(rightNow.getFullYear(), 0, 1, 0, 0, 0, 0);  // jan 1st
    june1 = new Date(rightNow.getFullYear(), 6, 1, 0, 0, 0, 0); // june 1st
    temp = jan1.toUTCString();
    jan2 = new Date(temp.substring(0, temp.lastIndexOf(" ") - 1));
    temp = june1.toUTCString();
    var june2 = new Date(temp.substring(0, temp.lastIndexOf(" ") - 1));
    var std_time_offset = (jan1 - jan2) / (1000 * 60 * 60);
    var daylight_time_offset = (june1 - june2) / (1000 * 60 * 60);
    var dst;
    if (std_time_offset == daylight_time_offset) {
        dst = "0"; // daylight savings time is NOT observed
    } else {
        // positive is southern, negative is northern hemisphere
        var hemisphere = daylight_time_offset - std_time_offset;
        if (hemisphere >= 0) {
            std_time_offset = daylight_time_offset;
            dst = "1"; // daylight savings time is observed
        }
    }
    var i;
    // Here set the value of hidden field to the ClientTimeZone.
    return minutes = localStorage["LocalTime"] = convert(std_time_offset);

}
var dtMonth, sDay, sYear, sHour, sMinute, sAMPM;
function formatDate(dateVal) {
    var newDate = new Date(dateVal);

    //var sMonth = padValue(newDate.getMonth() + 1);
    dtMonth = padValue(newDate.getMonth() + 1);
    sDay = padValue(newDate.getDate());
    sYear = newDate.getFullYear();
    sHour = newDate.getHours();
    sMinute = padValue(newDate.getMinutes());

    if (isNaN(dtMonth))
        return;
    sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
        sAMPM = "PM";
        sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
        sHour = "12";
    }

    sHour = padValue(sHour);

    switch (dtMonth) {
        case '01':
            dtMonth = '01';
            break;
        case '02':
            dtMonth = '02';
            break;
        case '03':
            dtMonth = '03';
            break;
        case '04':
            dtMonth = '04';
            break;
        case '05':
            dtMonth = '05';
            break;
        case '06':
            dtMonth = '06';
            break;
        case '07':
            dtMonth = '07';
            break;
        case '08':
            dtMonth = '08';
            break;
        case '09':
            dtMonth = '09';
            break;
        case '10':
            dtMonth = '10';
            break;
        case '11':
            dtMonth = '11';
            break;
        case '12':
            dtMonth = '12';
            break;
    }
    return dtMonth + "-" + sDay + "-" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
}

function padValue(value) {
    return (value < 10) ? "0" + value : value;
}
// This function is to convert the timezoneoffset to Standard format
function convert(value) {

    var hours = parseInt(value);
    value -= parseInt(value);
    value *= 60;
    var mins = value;
    value -= parseInt(value);
    value *= 60;
    var secs = parseInt(value);
    var display_hours = hours.toString();
    // handle GMT case (00:00)
    if (hours == 0) {
        display_hours = "00";
    } else if (hours > 0) {
        // add a plus sign and perhaps an extra 0
        display_hours = ((hours < 10) ? "+0" + hours : "+" + hours).toString();
    } else {
        // add an extra 0 if needed
        display_hours = ((hours > -10) ? "-0" + Math.abs(hours) : hours).toString();
    }
    mins = (mins < 10) ? "0" + mins : mins;
    return display_hours + ":" + mins;
}

var timeOffSet, hourOffSet, minuteOffSet,
    minutesOffSet, preValOffSet, hoursOffSet, final;
function convertOffset(gmt_offset) {
    timeOffSet = gmt_offset.toString().split(":");
    hourOffSet = parseInt(timeOffSet[0]);
    minuteOffSet = parseInt(timeOffSet[1]);
    minutesOffSet = minuteOffSet;
    preValOffSet = hourOffSet < 0 ? "-" : "+";
    hoursOffSet = Math.abs(hourOffSet) < 10 ? "0" + Math.abs(hourOffSet) : Math.abs(hourOffSet);
    //hour = negative ? "-" + hour : "+" + hour;
    final = parseInt(hoursOffSet);
    let data = final * 60;

    return preValOffSet + parseInt(data + minutesOffSet);
}
function GetLocalTimeFromUTC(date, formatRequired) {

    var dateStringWithTime = moment(date).format('DD-MM-YYYY HH:mm:ss');
    var dateArr = dateStringWithTime.split('-');
    var val = dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2];
    var dt_ = new Date(val);
    if (localStorage["LocalTime"] == null) {
        calculate_time_zone();
    }

    var TimeZoneHours = convertOffset(localStorage["LocalTime"]);
    let MILLISECS_PER_HOUR = TimeZoneHours /* min/hour */ * 60 /* sec/min */ * 1000 /* ms/s */;
    var newDate_ = new Date(+dt_ + MILLISECS_PER_HOUR);
    if (IsNull(formatRequired) != null) {
        newDate_ = moment(newDate_).format(formatRequired);
        return newDate_;
    }
    return formatDate(newDate_);
}


function dateFormat(date, format = 'MM-DD-YYYY') {
    if (IsNull(date) == null) {
        return '';
    }
    return GetLocalTimeFromUTC(date, format);
}


function TruncateStr(str, len) {
    var convertstring = '' + str;
    if (convertstring != null)
        if (convertstring.length <= len) return str;
        else return convertstring.substring(0, len) + "...";
}
function convertDecimalValueInFormat(value) {
    try {
        value = parseFloat(value, 10);
        value = value.toFixed(2);
        value = parseFloat(value, 10);
        value = value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return value;
    } catch (ex) {
        return value
    }
}
function objectToGetParams(object) {
    return (
        '?' +
        Object.keys(object)
            .filter(key => !!object[key])
            .map(key => `${key}=${encodeURIComponent(object[key])}`)
            .join('&')
    );
}
export default {
    IsNull,
    dateFormat,
    TruncateStr,
    convertDecimalValueInFormat,
    objectToGetParams,
}
