// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/sm-banner1.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/sm-banner2.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../images/sm-banner3.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../images/animal-care-offer.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../images/curbside-delivery.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../images/local-delivery.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../images/sale-offer-bg.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../images/full-offer.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".div-offerSect-blogimage{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:cover;background-position:50%}.div-offerSect-newsimage{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:cover;background-position:50%}.div-offerSect-Testimonialsimage{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:cover;background-position:50%}.div-Animal-image{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat;background-size:cover;background-position:50%}.div-Pickup-image{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") no-repeat;background-size:cover;background-position:50%}.div-Delivery-image{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") no-repeat;background-size:cover;background-position:50%}.div-sale-offer-image{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") no-repeat;background-size:cover;background-position:50%}.div-sale-ranchmap-image{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") no-repeat;background-size:cover;background-position:50%}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
