<head>
    <meta charset="utf-8">
    <title>BeeNee</title>

    <!--<a href="#"><img src="../../../../../assets/img/logo.png" alt="logo"></a>-->
    <!--<router-link :to="{ name: 'HomePage'}" @click.native="scrollToTop">
        <img :src="companyLogo" :alt="companyname">
    </router-link>-->

    <link rel='shortcut icon' href='../../assets/images/logo.png'>
    <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1">
</head>

<template>
    <!-- header -->
    <header class="header wrap-header">
        <div class="wrap-header-top">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-sm-6">
                        <div class="header-top-offer">
                            <span>Local Pickup for San Fernando Valley</span>|<span>All items are carefully selected from small, independent businesses.</span> 
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="header-top-link">
                            <!--<ul class="quick-link">
                            <li id="menu-item-17633" class="nav-item menu-item menu-item-type-post_type menu-item-object-page " v-for="link in HeaderTopNavlinks">
                                <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}" @click="CloseLeftMenu">{{link.displayText}}</router-link>
                            </li>
                        </ul>-->

                            <ul class="quick-link">
                                <li class="border-0">
                                    <div class="input-group add-on">
                                        <input class="form-control addon-text-box" v-model='searchTxt' placeholder="Search Products" type="text" @keyup.enter='search()'>
                                        <button type="submit" class="input-group-btn addon-btn" @click="search()">
                                            <i class="fa fa-search flip-icon"></i>
                                        </button>
                                    </div>
                                </li>
                                <template v-if="isUserLoggedIn">
                                    <li>
                                        <router-link to="/Orders">
                                            Order Tracking
                                        </router-link>
                                    </li>
                                </template>
                                <template v-else>
                                    <li>
                                        <router-link to="/Login">
                                            Order Tracking
                                        </router-link>
                                    </li>
                                </template>
                                <li>
                                    <router-link to="/Aboutus">
                                        About Us
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/contactus">
                                        Contact Us
                                    </router-link>
                                </li>
                                <!--<li>
        <router-link to="/FAQ">
            FAQs
        </router-link>
    </li>-->
                                <!--<li>
                                    <div class="shippingcheckbx">
                                        <input class="form-check-input me-2"
                                               type="checkbox"
                                               v-model="IsTopDawg"
                                               :id="inputId"
                                               @change="toggleIds">
                                        <label class="form-check-label" :for="inputId" id="labelId">Online Shipping available</label>
                                    </div>
                                </li>-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrap-mainmenu">
            <div class="container">
                <div class="header-navbar">
                    <div class="header-brand">
                        <!--<router-link to="/" class="logo">
                        <img alt="Site Logo" src="../../assets/images/logo.png" />
                    </router-link>-->
                        <!--<a href="#"><img src="../../../../../assets/img/logo.png" alt="logo"></a>-->
                        <router-link :to="{ name: 'Home'}">
                            <img :src="companyLogo" :alt="companyname">
                        </router-link>

                        <div class="brand_about">
                            <router-link to="/Aboutus">
                                <img src="../../assets/images/user-about.png" />
                                <span>
                                    STORY OF OUR <br />
                                    LEGENDARY FOUNDER
                                </span>
                            </router-link>
                        </div>


                    </div>
                    <div id="mobile_backButton" v-if="userName != null">Welcome, {{username}} <a @click="goToPreviousPage()"><i class="fa fa-arrow-left" aria-hidden="true"></i></a></div>

                    <div class="header-main-nav" @click="handleClickOutside" >
                        <nav class="mainmenu-nav" >
                            <div class="mobile-nav-brand"><a class="logo" href="#"><img src="../../assets/images/logo.png" /></a></div>
                            <ul class="mainmenu menu" ref="menu">
                                <!-- <li id="menu-item-17633" class="menu-item" v-for="link in NavLinks">
        <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}" @click="CloseLeftMenu">{{link.displayText}}</router-link>
    </li> -->
                                <li class="menu-item"><a href="/ProductList">All Products</a></li>

                                <li id="idActive-L1" class="menu-item r1">
                                   
                                    <a href="#" @click="toggleSubmenu(1)" class="mobileMenuitem" :class="{ active: activeMenu === 1 }">
                                        Dog  <i class="fa fa-chevron-down"></i>
                                        <svg class="pawIcon" id="paw-button" viewBox="0 -4 24 24" focusable="false" data-a11y="decorative" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path fill-rule="#222736" clip-rule="evenodd" d="M14.987 8.296h.208c.485 0 .97-.146 1.387-.511.693-.511 1.248-1.461 1.317-2.41.209-1.754-.832-3.215-2.219-3.36-.555-.074-1.11.145-1.595.437-.693.512-1.248 1.461-1.318 2.411-.208 1.753.833 3.287 2.22 3.433Zm-7.62-.51c.421.291.913.51 1.405.51h.21c1.476-.146 2.46-1.679 2.249-3.358-.07-1.095-.562-1.97-1.265-2.482a2.2 2.2 0 0 0-1.615-.438c-1.476.146-2.46 1.68-2.249 3.358.07.95.562 1.825 1.265 2.41Zm8.323 3.854A5.016 5.016 0 0 0 11.996 10a5.016 5.016 0 0 0-3.694 1.64l-3.103 3.428c-.96.97-1.404 2.385-1.108 3.727.295 1.416 1.255 2.46 2.733 2.981 1.404.522 3.03.075 4.063-1.043a1.497 1.497 0 0 1 1.109-.447c.443 0 .812.149 1.108.447A3.802 3.802 0 0 0 15.91 22c.444 0 .813-.075 1.256-.224 1.404-.521 2.438-1.64 2.734-2.981.295-1.342-.074-2.758-1.035-3.727L15.69 11.64Zm-9.617.156c-.275.136-.62.204-.895.204-.207 0-.413 0-.689-.136-.826-.203-1.583-.814-2.065-1.56-.758-1.358-.482-2.852.688-3.327.482-.203 1.033-.203 1.584-.067.826.203 1.584.814 2.066 1.56.757 1.358.482 2.851-.689 3.326ZM19.329 6.91c.546-.136 1.092-.136 1.57.067 1.159.543 1.432 2.037.681 3.394-.477.747-1.227 1.358-2.046 1.561-.205.068-.478.068-.682.068-.341 0-.614-.068-.887-.204-1.228-.542-1.5-2.036-.682-3.325.477-.747 1.228-1.358 2.046-1.561Z" fill="currentColor"></path></svg>
                                    </a>
                                    <!--v-if="activeMenu === 1"-->
                                    <div class="submenu">

                                        <ul>
                                            <!--<li><a href="#">Feed</a></li>-->
                                            <li>
                                                <a href="/ProductList/Tags/Dog-Food">
                                                    Food
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ProductList/Tags/Dog-Supplies">
                                                    Supplies
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ProductList/Tags/Dog-Collar">
                                                    Collar
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ProductList/Tags/Dog-Grooming">
                                                    Grooming
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ProductList/Tags/Dog-Brushes">
                                                    Brushes
                                                </a>
                                            </li>
                                        </ul>
                                    </div>


                                    <div class="submenu mobile_subMenu">

                                        <ul>
                                            <!--<li><a href="#">Feed</a></li>-->
                                            <li>
                                                <a href="/ProductList/Tags/Dog-Food">
                                                    Food
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ProductList/Tags/Dog-Supplies">
                                                    Supplies
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ProductList/Tags/Dog-Collar">
                                                    Collar
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ProductList/Tags/Dog-Grooming">
                                                    Grooming
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ProductList/Tags/Dog-Brushes">
                                                    Brushes
                                                </a>
                                            </li>
                                        </ul>
                                    </div>



                                </li>


                                <!-- <li class="menu-item"> <a href="/ProductList/Dog"> Dog</a></li> -->
                                <!--<li class="menu-item">
        <a href="#" @click="toggleSubmenu(1)" :class="{ active: activeMenu === 1 }">
            Dog  <i class="fa fa-chevron-down"></i>
            <svg class="pawIcon" id="paw-button" viewBox="0 -4 24 24" focusable="false" data-a11y="decorative" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path fill-rule="#222736" clip-rule="evenodd" d="M14.987 8.296h.208c.485 0 .97-.146 1.387-.511.693-.511 1.248-1.461 1.317-2.41.209-1.754-.832-3.215-2.219-3.36-.555-.074-1.11.145-1.595.437-.693.512-1.248 1.461-1.318 2.411-.208 1.753.833 3.287 2.22 3.433Zm-7.62-.51c.421.291.913.51 1.405.51h.21c1.476-.146 2.46-1.679 2.249-3.358-.07-1.095-.562-1.97-1.265-2.482a2.2 2.2 0 0 0-1.615-.438c-1.476.146-2.46 1.68-2.249 3.358.07.95.562 1.825 1.265 2.41Zm8.323 3.854A5.016 5.016 0 0 0 11.996 10a5.016 5.016 0 0 0-3.694 1.64l-3.103 3.428c-.96.97-1.404 2.385-1.108 3.727.295 1.416 1.255 2.46 2.733 2.981 1.404.522 3.03.075 4.063-1.043a1.497 1.497 0 0 1 1.109-.447c.443 0 .812.149 1.108.447A3.802 3.802 0 0 0 15.91 22c.444 0 .813-.075 1.256-.224 1.404-.521 2.438-1.64 2.734-2.981.295-1.342-.074-2.758-1.035-3.727L15.69 11.64Zm-9.617.156c-.275.136-.62.204-.895.204-.207 0-.413 0-.689-.136-.826-.203-1.583-.814-2.065-1.56-.758-1.358-.482-2.852.688-3.327.482-.203 1.033-.203 1.584-.067.826.203 1.584.814 2.066 1.56.757 1.358.482 2.851-.689 3.326ZM19.329 6.91c.546-.136 1.092-.136 1.57.067 1.159.543 1.432 2.037.681 3.394-.477.747-1.227 1.358-2.046 1.561-.205.068-.478.068-.682.068-.341 0-.614-.068-.887-.204-1.228-.542-1.5-2.036-.682-3.325.477-.747 1.228-1.358 2.046-1.561Z" fill="currentColor"></path></svg>
        </a>
        <div v-if="activeMenu === 1" class="submenu">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                        <img src="../../assets/images/dog.jpg" class="img-fluid" />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                        <h6><span>Health & Pharmacy</span></h6>
                        <ul>
                            <li><a href="/Products/ea95da8e-fccb-4a07-8ec7-308b23a31a62">Vitamins And Supplements</a></li>
                            <li><a href="/Products/1c110220-558c-4125-892c-bdbfe108a399">Remedies</a></li>
                            <li><a href="/Products/95efd2bb-bea7-4490-94f3-94789220f6a4">Pest Control</a></li>
                            <li><a href="/Products/467e1f80-8324-43b7-8cd0-97c9c45f9f0f">Animal Care</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                        <h6><span>Supplies</span></h6>
                        <ul>
                            <li><a href="/Products/a8e3c717-8867-44aa-882e-ec8ee02a6996">Pet Food</a></li>
                            <li><a href="/Products/170b050c-96e0-4808-93d6-069c60b3d5c4">Pet Supplies</a></li>
                            <li><a href="/Products/74d9b43a-6d8a-4e97-b215-457da3143fec">Pet Treats And Toys</a></li>
                        </ul>
                    </div>



                </div>
            </div>

        </div>
    </li>-->
                                <!-- <li class="menu-item">
        <a href="/ProductList/Cat"> Cat   </a>
    </li> -->

                                <li class="menu-item r1 remove-li-class">
                                    <a href="#" @click="toggleSubmenu(3)" class="mobileMenuitem" :class="{ active: activeMenu === 3 }">
                                        Cat  <i class="fa fa-chevron-down"></i>
                                        <svg class="pawIcon" id="paw-button" viewBox="0 -4 24 24" focusable="false" data-a11y="decorative" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path fill-rule="#222736" clip-rule="evenodd" d="M14.987 8.296h.208c.485 0 .97-.146 1.387-.511.693-.511 1.248-1.461 1.317-2.41.209-1.754-.832-3.215-2.219-3.36-.555-.074-1.11.145-1.595.437-.693.512-1.248 1.461-1.318 2.411-.208 1.753.833 3.287 2.22 3.433Zm-7.62-.51c.421.291.913.51 1.405.51h.21c1.476-.146 2.46-1.679 2.249-3.358-.07-1.095-.562-1.97-1.265-2.482a2.2 2.2 0 0 0-1.615-.438c-1.476.146-2.46 1.68-2.249 3.358.07.95.562 1.825 1.265 2.41Zm8.323 3.854A5.016 5.016 0 0 0 11.996 10a5.016 5.016 0 0 0-3.694 1.64l-3.103 3.428c-.96.97-1.404 2.385-1.108 3.727.295 1.416 1.255 2.46 2.733 2.981 1.404.522 3.03.075 4.063-1.043a1.497 1.497 0 0 1 1.109-.447c.443 0 .812.149 1.108.447A3.802 3.802 0 0 0 15.91 22c.444 0 .813-.075 1.256-.224 1.404-.521 2.438-1.64 2.734-2.981.295-1.342-.074-2.758-1.035-3.727L15.69 11.64Zm-9.617.156c-.275.136-.62.204-.895.204-.207 0-.413 0-.689-.136-.826-.203-1.583-.814-2.065-1.56-.758-1.358-.482-2.852.688-3.327.482-.203 1.033-.203 1.584-.067.826.203 1.584.814 2.066 1.56.757 1.358.482 2.851-.689 3.326ZM19.329 6.91c.546-.136 1.092-.136 1.57.067 1.159.543 1.432 2.037.681 3.394-.477.747-1.227 1.358-2.046 1.561-.205.068-.478.068-.682.068-.341 0-.614-.068-.887-.204-1.228-.542-1.5-2.036-.682-3.325.477-.747 1.228-1.358 2.046-1.561Z" fill="currentColor"></path></svg>
                                    </a>
                                    <!--v-if="activeMenu === 3"-->
                                    <div class="submenu">

                                        <ul>
                                            <!--<li><a href="#">Feed</a></li>-->
                                            <li><a href="/ProductList/Tags/Cat-Food">Food</a></li>
                                            <li>
                                                <a href="/ProductList/Tags/Cat-Supplies">
                                                    Supplies
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ProductList/Tags/Cat-Grooming">
                                                    Grooming
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="submenu mobile_subMenu">

                                        <ul>
                                            <!--<li><a href="#">Feed</a></li>-->
                                            <li><a href="/ProductList/Tags/Cat-Food">Food</a></li>
                                            <li>
                                                <a href="/ProductList/Tags/Cat-Supplies">
                                                    Supplies
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ProductList/Tags/Cat-Grooming">
                                                    Grooming
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </li>




                                <!-- <li class="menu-item">
        <a href="/ProductList/Horse"> Horse   </a>
    </li>  -->
                                <li class="menu-item r1 remove-li-class">
                                    <a href="#" @click="toggleSubmenu(2)" class="mobileMenuitem" :class="{ active: activeMenu === 2 }">
                                        Horse  <i class="fa fa-chevron-down"></i>
                                        <svg class="pawIcon" id="paw-button" viewBox="0 -4 24 24" focusable="false" data-a11y="decorative" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path fill-rule="#222736" clip-rule="evenodd" d="M14.987 8.296h.208c.485 0 .97-.146 1.387-.511.693-.511 1.248-1.461 1.317-2.41.209-1.754-.832-3.215-2.219-3.36-.555-.074-1.11.145-1.595.437-.693.512-1.248 1.461-1.318 2.411-.208 1.753.833 3.287 2.22 3.433Zm-7.62-.51c.421.291.913.51 1.405.51h.21c1.476-.146 2.46-1.679 2.249-3.358-.07-1.095-.562-1.97-1.265-2.482a2.2 2.2 0 0 0-1.615-.438c-1.476.146-2.46 1.68-2.249 3.358.07.95.562 1.825 1.265 2.41Zm8.323 3.854A5.016 5.016 0 0 0 11.996 10a5.016 5.016 0 0 0-3.694 1.64l-3.103 3.428c-.96.97-1.404 2.385-1.108 3.727.295 1.416 1.255 2.46 2.733 2.981 1.404.522 3.03.075 4.063-1.043a1.497 1.497 0 0 1 1.109-.447c.443 0 .812.149 1.108.447A3.802 3.802 0 0 0 15.91 22c.444 0 .813-.075 1.256-.224 1.404-.521 2.438-1.64 2.734-2.981.295-1.342-.074-2.758-1.035-3.727L15.69 11.64Zm-9.617.156c-.275.136-.62.204-.895.204-.207 0-.413 0-.689-.136-.826-.203-1.583-.814-2.065-1.56-.758-1.358-.482-2.852.688-3.327.482-.203 1.033-.203 1.584-.067.826.203 1.584.814 2.066 1.56.757 1.358.482 2.851-.689 3.326ZM19.329 6.91c.546-.136 1.092-.136 1.57.067 1.159.543 1.432 2.037.681 3.394-.477.747-1.227 1.358-2.046 1.561-.205.068-.478.068-.682.068-.341 0-.614-.068-.887-.204-1.228-.542-1.5-2.036-.682-3.325.477-.747 1.228-1.358 2.046-1.561Z" fill="currentColor"></path></svg>
                                    </a>

                                    <div class="submenu mobile_subMenu">

                                        <ul>
                                            <!--<li><a href="#">Feed</a></li>-->
                                            <li><a href="/ProductList/Tags/Feed">Feed</a></li>
                                            <li><a href="/ProductList/Tags/Apparel">Apparel</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Supplies">Supplies</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Grooming">Grooming</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Equipment">Equipment</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Health">Health</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Supplements">Vitamins &amp; Supplements</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Care">Horse Care</a></li>
                                        </ul>
                                    </div>


                                    <!--v-if="activeMenu === 2"-->
                                    <div class="submenu" >

                                        <ul>
                                            <!--<li><a href="#">Feed</a></li>-->
                                            <li><a href="/ProductList/Tags/Horse-Feed">Feed</a></li>
                                            <li><a href="/ProductList/Tags/Apparel">Apparel</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Supplies">Supplies</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Grooming">Grooming</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Equipment">Equipment</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Health">Health</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Supplements">Vitamins &amp; Supplements</a></li>
                                            <li><a href="/ProductList/Tags/Horse-Care">Horse Care</a></li>
                                        </ul>


                                        <!-- <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-3 mb-3 mb-lg-0">
                    <img src="../../assets/images/mega-menu-horse.jpg" class="img-fluid" />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-3 mb-lg-0">
                    <h6><span>Clothing & Accessories</span></h6>
                    <ul>
                        <li><a href="/Products/cd7e5112-7887-4efe-bb4a-0ce5cf477ada">Jewelry and Buckles</a></li>
                        <li><a href="/Products/1b5f0c51-e4ba-4dce-a591-9a86643b06d7">Western Saddles</a></li>
                        <li><a href="/Products/e629dcf9-1e29-4f0d-9a42-b8e415c62dca">Western Tack</a></li>
                        <li><a href="/Products/2ada77f5-f29c-442c-be89-ee780c7a9b77">Mexican Tack</a></li>
                        <li><a href="/Products/51d41fda-1a7a-4790-88f2-1a5dc84b9195">English Tack</a></li>
                        <li><a href="/Products/030ee7b2-7c14-4799-b697-96fdeeb579ef">Grooming Equipment</a></li>
                    </ul>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-3 mb-lg-0">
                    <h6><span>Health & Pharmacy</span></h6>
                    <ul>
                        <li><a href="/Products/ea95da8e-fccb-4a07-8ec7-308b23a31a62">Vitamins And Supplements</a></li>
                        <li><a href="/Products/1c110220-558c-4125-892c-bdbfe108a399">Remedies</a></li>
                        <li><a href="/Products/95efd2bb-bea7-4490-94f3-94789220f6a4">Pest Control</a></li>
                        <li><a href="/Products/467e1f80-8324-43b7-8cd0-97c9c45f9f0f">Animal Care</a></li>
                    </ul>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-3 mb-lg-0">
                    <h6><span>Supplies</span></h6>
                    <ul>
                        <li><a href="/Products/9facf684-af4f-4463-8c55-dc940ad4ed76">Hay</a></li>
                        <li><a href="/Products/e63766eb-9263-46ff-b642-1a024979da90">Feed</a></li>
                        <li><a href="/Products/39111c83-5400-4584-bc6f-5b7de0705b5b">Treats/Toys</a></li>
                    </ul>
                </div>


            </div>
        </div> -->

                                    </div>

                                </li>
                                <li class="menu-item remove-li-class"><a href="/ProductList">Other Animals</a></li>
                                <li class="menu-item remove-li-class"><a href="/Products/0478ba61-f31b-4026-9de2-547192aaee49">Apparel</a></li>
                                <li class="menu-item remove-li-class"><a href="/Products/1c110220-558c-4125-892c-bdbfe108a399">Remedies</a></li>
                            </ul>
                        </nav>
                        <div class="closeMask"></div>
                    </div>


                    <div class="header-action">
                        <ul class="action-list">
                            <!--<li>
                                <div class="round-btn" id="show-search-box" @click="Tooglesearch()">
                                    <i class="fas fa-search"></i>
                                </div>
                                <div id="hidden-search-box" class="navbar-form hidden-search-box" role="search">
                                    <div class="traingle"></div>
                                    <div class="input-group add-on">
                                        <input class="form-control addon-text-box"  v-model='searchTxt' placeholder="Search Product" name="s" type="text">
                                        <button type="submit" class="input-group-btn addon-btn" @click="search()">
                                            <i class="fa fa-search flip-icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>-->
                            <template v-if="!isUserLoggedIn">
                                <li class="signin">
                                    <router-link class="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" :to="{name:'SignUp'}" @click="OpenRightMenu">
                                        <div class="navbar-tool-icon-box"><i class="fa-solid fa-user-plus me-2"></i></div>
                                        <div class="navbar-tool-text ms-n3">Sign Up</div>
                                    </router-link>
                                </li>
                                <li class="signin">
                                    <router-link class="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" :to="{name:'Login'}" @click="OpenRightMenu">
                                        <div class="navbar-tool-icon-box"><i class="fa-regular fa-user"></i></div>
                                        <div class="navbar-tool-text ms-n3"><small v-if="$filters.IsNull(name,true,null)!=null">{{name.firstName}} {{name.lastName}} </small>Login</div>
                                    </router-link>
                                </li>

                            </template>
                            <template v-if="isUserLoggedIn">
                                <li class="signin">
                                    <a class="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" href="javascript:;">
                                        <div class="navbar-tool-icon-box"><i class="fa-regular fa-user"></i></div>
                                        <div class="navbar-tool-text ms-n3"><small v-if="$filters.IsNull(name,true,null)!=null">{{name.firstName}} {{name.lastName}} </small>Welcome, {{username}} </div>
                                    </a>
                                    <ul>
                                        <li>
                                            <a class="nav-link" href="javascript:;" @click="goToPortalDashboard">
                                                My Profile
                                            </a>
                                        </li>
                                        <li>
                                            <a class="nav-link" href="javascript:;" @click="Logout">
                                                Logout
                                            </a>
                                        </li>

                                    </ul>
                                </li>
                            </template>
                            &nbsp;
                            <li>
                                <a class="nav-link cart-icon" href="javascript:void(0);" @click="redirecttoCart(Id)" title="Cart"><span class="jquert_text">{{Shopping}}</span><i class="fas fa-shopping-cart"></i></a>


                            </li>
                            <li class="axil-mobile-toggle"><button class="menu-btn mobile-nav-toggler"><i class="fa-solid fa-bars"></i></button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {{Headerpage}}
        <section class="story-banner">
            <div class="container">
                <!--<div class="story-banner-main" @click="legendary()" style="cursor:pointer">
                <div class="row">
                    <div class="col-sm-6 ">
                        <div class="fusano-story">
                            STORY OF OUR <br />LEGENDARY FOUNDER
                        </div>
                    </div>
                    <div class="col-sm-6 proudly-american-box">
                        <div class="proudly-american">
                            PROUDLY AMERICAN

                        </div>
                    </div>
                </div>
            </div> -->
                <div class="row">
                    <div class="col-md-8">
                        <div class="story_subLine">Supporting Small Businesses and Independent Farmers for over 50 years</div>
                    </div>
                    <div class="col-md-4">
                        <div class="prodamer"> Proudly American <img src="../../assets/images/usaflag.jpg" alt="Service image"></div>
                    </div>
                </div>



            </div>
        </section>

    </header>
    
    <Home v-if="homes" :home="home" :searchValue="searchValue" @Homesss="Homesss"></Home>
</template>

<script>
    /*  import eventBus from './event-bus';*/
    import EcommerceService from '@/service/ECommerce.js'
    import AuthUserServices from '@/service/AuthUserServices.js';
    import userAuthService from '@/service/userAuthService';
    import { createConfirmDialog } from "vuejs-confirm-dialog";
    import UserService from '@/service/user.service';
    import Home from "../../views/HomeView.vue"
    import SimpleDialog from '@/views/shared/SimpleDialog.vue';
    import * as signalR from '@microsoft/signalr';
    import service from '@/service/FrontendService.js'
    export default {
        name: "HeaderView",
        components: {
            Home
        },
        //emits: ['logout'],
        data() {
            const hubConnection = new signalR.HubConnectionBuilder()
                .configureLogging(signalR.LogLevel.Debug)
                .withUrl('https://remoteservice.beenee.com/userHub',{
                    /*.withUrl('https://localhost:7015/userHub', {*/
                    //skipNegotiation: true,
                    //transport: signalR.HttpTransportType.WebSockets
                })
                .build();
            let name = {
                firstName: '',
                lastName: ''
            }
            return {
                Shopping:'0',
                scrollPosition: null,
                isscrolled: false,
                searchValue: '',
                homes: false,
                form: {
                    username: "",
                    password: "",
                },
                ShippingInformation: localStorage.getItem("ShippingInformation"),
                companyLogo: '',
                IsOpenLeftMenu: false,
                IsOpenRightMenu: false,
                hubConnection,
                companyname: localStorage.getItem('companyName'),

                companyId: localStorage.getItem("companyId"),
                showError: false, headerData: null, imageLink: null, name, logoPrimary: null, logoSecondary: null,
                isLoading: false, moduleList: [], activeIndex: 0, moduleList2: [],
                Svg: ' <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>',
                //userName: ''
                userName: localStorage.getItem('userName'),
                isSearchBoxVisible: false,
                BrowserUserId: null,
                searchTxt: '',
                BrowserUserId: null,
                activeMenu: null,
                IsTopDawg: true,
                inputId: 'abab73b8-b3bf-4951-8898-8e913653bb7f',
                labelId: 'lbl-abab73b8-b3bf-4951-8898-8e913653bb7f',
                username:''
            }
        },
        props: ['NavLinks', 'isUserLoggedIn', 'sideMenuItems', 'name', 'ProfilePic', 'Headerpage'],


        created() {
            this.hubConnection.start({ transport: 'ServerSentEvents' }).then(() => {
                //alert('This connected for that time');
            });
            if (localStorage.getItem('userlogin') != "false") {
                this.GetUserData();
            }
            this.GetCartListData();;
            /* const globalVar = inject('globalVariable')*/
            this.getCompanyLogo();
            this.isMobile();
            //this.toggleIds();
           
            $(document).ready(function () {

                $('#cartOpen').click(function () {
                    $('.cart-wrapper').addClass('open');
                });
                $('#cartClose').click(function () {
                    $('.cart-wrapper').removeClass('open');

                });

                $(".mobile-nav-toggler").click(function () {

                    const element = document.querySelector('.closeMask')
                    const style = getComputedStyle(element)
                    const backgroundColor = style.display

                    if (backgroundColor == 'block')
                    {
                        $(".header-main-nav").removeClass('open');
                        $(".closeMask").removeClass('active');
                    }
                    else{
                        $(".header-main-nav").addClass('open');
                        $(".closeMask").addClass('active');
                    }

                });

                $(".closeMask").click(function () {
                    $(this).removeClass('active');
                    $(".header-main-nav").removeClass('open');
                });

                

            });

            

            this.GetModuleList();
        },
        mounted() {
            debugger
            //let currentCompanyId = localStorage.getItem('currentcompanyId');
            ////for local
            ////'ABAB73B8-B3BF-4951-8898-8E913653BB7F'
            //// for stage
            //// 57148df2-ad21-4117-bbda-0244d1783c87

            //if (currentCompanyId === "57148df2-ad21-4117-bbda-0244d1783c87") {
            //    this.IsTopDawg = true;
            //} else {
            //    this.IsTopDawg = false;
            //}

            this.hubConnection.on('ReceiveMessage', (message, user, count) => {
                this.GetCartListData();

            });
            

            //const storedUsername = JSON.parse(localStorage.getItem('username'));
            //console.log('this.storedUsername',localStorage.getItem('username'));
            //// Check if the username exists in localStorage
            ////console.log('user information',localStorage.getItem('userinfo'));
            //if (storedUsername) {
            //    // If exists, update the data property
            //    this.username = storedUsername.userName;
            //    alert(this.username);
            //}

            window.addEventListener('scroll', this.updateScroll);
            document.addEventListener("click", this.handleClickOutside);
        },
        beforeDestroy() {
            // Clean up event listener to avoid memory leaks
            document.removeEventListener("click", this.handleClickOutside);
        },
        methods: {
            async initializeSignalR() {
                debugger
                if (this.$hubConnection.state === signalR.HubConnectionState.Disconnected) {
                    try {
                        await this.$hubConnection.start();
                    } catch (error) {
                        console.error('Error starting SignalR connection:', error);
                    }
                    this.$hubConnection.onreconnected(connectionId => {
                    });
                }
            },
            toggleSubmenu(menuIndex) {
                 //Stop event from propagating and closing submenu when clicking inside;
                event.stopPropagation();

                 //Toggle the submenu open/close based on the menu item clicked
                this.activeMenu = this.activeMenu === menuIndex ? null : menuIndex;
                
            },
            handleClickOutside(event) {
                const menu = this.$refs.menu;

                // If the click is outside the menu, close the submenu
                if (!menu.contains(event.target)) {
                    this.activeMenu = null;
                }
                //$('#idActive-L1').removeClass('active-L1');
            },

            goToPreviousPage() {
                this.$router.go(-1);
            },
            isMobile() {
                //debugger;
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    $(window).scroll(function () {
                        if ($(window).scrollTop() >= 60) {
                            $('.wrap-header').addClass('fixed_pos');
                        }
                        else {
                            $('.wrap-header').removeClass('fixed_pos');
                        }
                    });

                }
                else {
                    $(window).scroll(function () {
                        if ($(window).scrollTop() >= 330) {
                            $('.wrap-header').addClass('fixed');
                        }
                        else {
                            $('.wrap-header').removeClass('fixed');
                        }
                    });
                }
            },

            redirecttoCart(Id) {
                //debugger;
                //if (localStorage.getItem('userId') == null || localStorage.getItem('userId') == '') {
                //    this.$router.push({ path: `/Login` });
                //}
                //else {
                //    this.$router.push({ path: `/Cart` })
                //}
                this.$router.push({ path: `/Cart` })
            },
            Homesss() {
                //debugger;
                this.homes = false;
            },
            //Search() {
            //    // debugger;

            //    /*this.$router.push({ path: 'Home', params: { searchValue: this.searchValue } })*/

            //    this.searchValue;
            //    this.homes = true
            //},
            //Tooglesearch() {
            //    debugger;
            //    $('#show-search-box').click(function () {
            //        $("#hidden-search-box").toggle();
            //    });
            //},
            search() {
                debugger;
                if (this.$route.name == 'Products' || this.$route.name == 'ProductList' || this.$route.name == 'ProductList') {
                    localStorage.setItem('searchTxt', this.searchTxt);
                    this.searchTxt = '';
                    this.$router.push({ path: `/SearchProduct` })
                }
                else {
                    localStorage.setItem('searchTxt', this.searchTxt);
                    this.searchTxt = '';
                    this.$router.push({ path: `/ProductList` })
                }
            },
            goToPortalDashboard() {
                this.OpenRightMenu();
                /*        this.$emit('goToPortal')*/
                this.$router.push({ path: `/UserprofilePage` })
            },
            async Logout() {
                /*   const IPAddress = await UserService.GetIPAddress();*/
                const { reveal, onConfirm } = createConfirmDialog(SimpleDialog, {
                    question: `Are you sure you want to Logout?`,
                    header: `Logout`,
                    cancel: `No`,
                    confirm: `Yes`,
                });
                onConfirm(() => {
                    this.isLoading = true;
                    localStorage.removeItem("CurrentCartBrowerUserId");
                    this.$router.push('/logoutRedirect/:origin');
                    setTimeout(() => { this.isLoading = false }, 2500);
                });
                reveal(() => {

                });
            },

            OpenLoginPage() {
                this.$router.push({ path: 'Login' });
            },

            OpenSingupPage() {
                this.$router.push({ path: 'SignUp' });
            },

            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companySecondaryLogo'); //localStorage.getItem('companySecondaryLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../assets/images/logo.png') : logoUrl;
            },
            //getCompanyLogo() {
            //    let logoUrl = "";

            //    AuthUserServices.GetDynamicContentForComapny(localStorage.getItem("companyId")).then(res => {
            //        console.log('logo detail for home', res.data)
            //        logoUrl = res.data.secondaryLogoLink;
            //        this.companyLogo = logoUrl;
            //    })
            //    //(this.$filters.IsNull(logoUrl, 'null') == 'null') ?
            //    if (logoUrl == null || logoUrl == "") {
            //        this.companyLogo = require('../../assets/images/logo/logo.png');
            //    }
            //},
            async GetCartListData() {
                this.isLoading = true;
                // debugger;
                let BrowserUserId = localStorage.getItem("CurrentCartBrowerUserId");
                if (BrowserUserId != null) {
                    this.BrowserUserId = BrowserUserId;
                }
                else {
                    this.BrowserUserId = null;
                }

                await AuthUserServices.GetCartListData(localStorage.getItem('CurrentCartId'), "yes", localStorage.getItem('cartaction'), localStorage.getItem("userId"), localStorage.getItem("CurrentCartId"), this.BrowserUserId).then((response) => {
                    //  debugger;
                    if (response.data && response.data._CartsModel && Array.isArray(response.data._CartsModel)) {
                        this.Shopping = response.data._CartsModel.length;
                    }
                    //this.Shopping = response.data._CartsModel.length;
                })
            },
            redirectOrderTracking() {
                if (!this.isUserLoggedIn) {
                    this.$router.push({ path: '/Login' })
                }
                else {
                    this.$router.push({ path: '/Orders' })
                }
            },
            OpenRightMenu() {
                this.IsOpenRightMenu = (this.IsOpenRightMenu) ? false : true;
                let navbar = document.getElementsByClassName("navbar-default");
                let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                let sectionFooter = document.getElementsByClassName("section-footer");
                let sectioncomparison = document.getElementsByClassName("comparisonblock");
                let footerbottom = document.getElementById('footer-bottom');
                if (this.IsOpenRightMenu) {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < sectioncomparison.length; i++) {
                        sectioncomparison[i].classList.add("show-menu1");
                    }
                    // footerbottom.classList.add("show-menu1");
                } else {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < sectioncomparison.length; i++) {
                        sectioncomparison[i].classList.remove("show-menu1");
                    }
                    //  footerbottom.classList.remove("show-menu1");
                }

            }, ToggleLeftMenu(Action = null) {
                this.IsOpenLeftMenu = (this.IsOpenLeftMenu) ? false : true;
                let navbar = document.getElementsByClassName("navbar-default");
                let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                let sectionFooter = document.getElementsByClassName("section-footer");
                let footerbottom = document.getElementById('footer-bottom');
                if (this.IsOpenLeftMenu) {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.add("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.add("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.add("show-menu");
                    }
                    //footerbottom.classList.add("show-menu");
                } else {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu");
                    }
                    //footerbottom.classList.remove("show-menu");
                }
            },
            CloseLeftMenu() {
                if (this.IsOpenLeftMenu) {
                    let navbar = document.getElementsByClassName("navbar-default");
                    let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                    let sectionFooter = document.getElementsByClassName("section-footer");

                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu");
                    }
                    this.IsOpenLeftMenu = false

                }
            },

            updateScroll() {
                this.scrollPosition = window.scrollY;
                if (this.scrollPosition != 0) {
                    this.isscrolled = true;
                }
                else {
                    this.isscrolled = false;
                }

            },
            checkCurrentRoute(item) {

                if (item.moduleCode == this.$router.currentRoute.value.meta.moduleCode) {
                    return true;
                }
                else {
                    return false;
                }
            },
            async GetModuleList() {
                var id = localStorage.getItem("userId");
                if (id != null) {
                    await userAuthService.GetModuleList(localStorage.getItem("userId"), "00Test").then(response => (
                        this.moduleList = [],
                        this.moduleList = response.data,
                        console.log("moduleList ", this.moduleList)
                    ))
                }
            },
            checkParentRoute(modulCode) {
                var parentModuleCode = this.moduleList.filter(itm => itm.moduleCode === modulCode);
                if (parentModuleCode[0].subModules != null) {
                    var childModuleCode = parentModuleCode[0].subModules.filter(itm => itm.moduleCode === this.$router.currentRoute.value.meta.moduleCode);
                    if (childModuleCode.length > 0) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else if (parentModuleCode[0].subModules == null) {
                    if (modulCode == this.$router.currentRoute.value.meta.moduleCode) {
                        return true;
                    }
                }
                return false;
            },

            ForSrceenChange() {

                let widthwindow = window.screen.width;

                let headerElement = document.getElementById('top-page-header')
                let sideElement = document.getElementById('nav-page-sidebar');

                if (widthwindow <= 991) {
                    sideElement.classList.add("close_icon");
                    headerElement.classList.add("close_icon");
                }
                else {
                    if (sideElement.classList != null) {

                        sideElement.classList.remove("close_icon");

                    }
                    if (headerElement.classList != null) {

                        headerElement.classList.remove("close_icon");

                    }

                }
            },
            redirectToPath(path) {
                if (path == 'home') {
                    window.location.assign(`${this.$custConfig.companyPortalUrl}/${localStorage.getItem('companyUrl')}/Home`)
                }
                this.ForSrceenChange();
                this.$router.push(path)
            },
            GetHeaderData() {

                UserService.getHeaderData(localStorage.getItem("userId"), '00Test').then(res => {
                    this.headerData = res.data;
                    this.imageLink = res.data.profilePic;

                })
            },
            //toggleIds() {
            //    debugger
            //    localStorage.removeItem("currentcompanyId");
            //    if (this.IsTopDawg) {
            //        // for local ABAB73B8-B3BF-4951-8898-8E913653BB7F
            //        //this.inputId = 'ABAB73B8-B3BF-4951-8898-8E913653BB7F';
            //        //this.labelId = 'lbl-ABAB73B8-B3BF-4951-8898-8E913653BB7F';
            //        //localStorage.setItem('currentcompanyId', 'ABAB73B8-B3BF-4951-8898-8E913653BB7F');
            //        //$('#labelId').text('Online Shipping available.');
            //        //setTimeout(() => { this.loadSave = false; window.location.href = "/Home"; }, 1000);

            //        // for live 57148df2-ad21-4117-bbda-0244d1783c87
            //        this.inputId = '57148df2-ad21-4117-bbda-0244d1783c87';
            //        this.labelId = 'lbl-57148df2-ad21-4117-bbda-0244d1783c87';
            //        localStorage.setItem('currentcompanyId', "57148df2-ad21-4117-bbda-0244d1783c87");
            //        $('#labelId').text('Online Shipping available');
            //        setTimeout(() => { this.loadSave = false; window.location.href = "/Home"; }, 1000);
   
            //    }
            //    else {
            //        // for local
            //        //this.inputId = this.companyId;
            //        //this.labelId = 'lbl-00000000-0000-0000-0000-000000000000';
            //        //localStorage.setItem('currentcompanyId', 'C3BBECB8-F979-41AD-9B6D-80F8F0C2B826');
            //        //$('#labelId').text('Local Delivery/Store Pickup option available.');
            //        //setTimeout(() => { this.loadSave = false;  window.location.href = "/Home"; }, 1000);

            //        // for live
            //        this.inputId = this.companyId;
            //        this.labelId = 'lbl-00000000-0000-0000-0000-000000000000';
            //        localStorage.setItem('currentcompanyId', "C3BBECB8-F979-41AD-9B6D-80F8F0C2B826");
            //        $('#labelId').text('Local Delivery/Store Pickup option available.');
            //        setTimeout(() => { this.loadSave = false; window.location.href = "/Home"; }, 1000);
            //    }
            //},
            async GetUserData() {
                this.isLoading = true;
                await EcommerceService.getUserDetail(localStorage.getItem('userId'), "optionalTest").then(response => {
                    let result = response.data;
                    if (result) {
                        this.username = result.userName;
                    } this.isLoading = false;
                }).catch(error => { this.isLoading = false; console.log('erron', error.response) })
            },
        },
        destroy() {
            window.removeEventListener('scroll', this.updateScroll)
        },


    }
</script>