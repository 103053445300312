<template>
    <div class="modal-container cmnmodel small-modal">
        <div class="modal-dialog modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content pt-2 pb-2">

                <div class="modal-body text-center">
                    <div class="icon-exp"><i class="fa-solid fa-exclamation"></i></div>
                    <h5 class="modal-title">  {{header}}</h5>
                    {{ question }}
                </div>
                <div class="modal-footer align-items-center justify-content-center border-0 pt-0 pb-4">
                    <!--<button class="btn btn-pill btn-gradient color-4" @click="$emit('confirm')" style="min-width:100px">{{confirm}}</button>-->
                    <button  class="btn btn-primary mx-3" @click="$emit('confirm')" style="min-width:100px">{{confirm}}</button>
                    <button class="btn btn-secondary" @click="$emit('cancel','onCancel')">{{cancel}}</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { defineComponent } from "vue";

    export default defineComponent({
        props: ["question", "header", "cancel", "confirm"],
        emits: ["confirm", "cancel", "onCancel"],
    });

</script>

<style>
    .cmnmodel .modal-dialog {
        max-width:450px;
    }
    .cmnmodel .btn-secondary {
        border-radius: 5px;
        padding:;
        line-height: 12px;
        min-height: auto;
        height: 43px;
    }

    .cmnmodel .btn {
        padding: 9px 34px;
        font-size: 16px;
    }
    .icon-exp {
        border: 2px solid #ffb300;
        color: #ffb300;
        width: 60px;
        height: 60px;
        margin: 0 auto;
        border-radius: 50%;
        font-size: 32px;
        line-height: 52px;
        margin-bottom: 0px;
    }
    .modal-title {
        font-size: 27px;
    }

</style>